<template>
  <div class="Have_to_buy_goods">
    <div class="List">
      <p class="List_title">消息列表</p>
      <div class="Mine">
        <p class="out" v-if='!information.length'>暂无消息</p>
        <template v-else>
          <ul>
            <li v-for="(item, index) in information" :key="index">
              <i class="el-icon-document" style="margin-right:10px;"></i>

              <dl>
                <dt class="hover" @click="open(item)">
                  {{item.message_title}}

                </dt>
                <dd v-if='item.expand'>
                  {{item.message_body}}

                </dd>

              </dl>

            </li>
          </ul>
         
        </template>
      </div>
    </div>

  </div>
</template>
<script type="text/javascript">
export default {
  data() {
    return {
      show: false,
      searchData: {
        member_id: this.$store.state.member.id,
        page: 1,
        limit: 10
      },
      show1: false,
      loop: undefined,
      information: [],
      tipsQuantity: 0
    };
  },
  methods: {
    open(item) {
      item.expand = !item.expand

    },
    currentChange() {
      this.$get("home/message", this.searchData).then(res => {

        res.items.data.forEach(item => {
          this.$set(item, "expand", false);
        });
        this.information = res.items.data;

        console.log('expand', this.information)

        this.searchData.total = res.total;
      });
    }
  },
  components: {
    // NoticeListHome,
    // NoticeDetail
  },
  mounted() {
    this.currentChange();
  },
  created() { }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.Have_to_buy_goods {
  width: 100%;

  background: #fff;
  .List {
    .List_title {
      font-size: 14px;
      width: 100%;
      height: 40px;
      line-height: 40px;
      background: #e94c15;
      color: #fff;
      font-weight: 600;
      padding-left: 10px;
    }
  }
}
.Mine {
  .out {
    height: 50px;
    line-height: 50px;
    width: 100%;
    text-align: center;
  }
  width: 100%;
  // height: 190px;
  // background: #f5f5f5;
  padding: 0 5px;
  // margin-top: 230px;
  .spClik {
    display: block;
    width: 100%;
  }
  ul {
    height: 600px;
    li {
      margin-bottom: 5px;
      display: block;
      font-size: 12px;
      padding: 5px 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-top: 5px;
      border-bottom: 1px dashed #ccc;
      padding: 10px 0;
      cursor: pointer;
      @include row;
      dl {
        display: block;
        width: 100%;
        box-sizing: border-box;
        dt {
          font-weight: bold;
          display: block;
        }
        dd {
          color: #666;
          margin-top: 5px;
        }
      }
    }
  }
  // ul li:hover {
  //   border-bottom: 1px solid #ccc;
  //   color: #e94c15;
  // }
  ul li:nth-child(1) {
    margin-top: 7px;
  }
}
.Detail {
  min-height: 1000px;
  .mine {
    margin-top: 10px;
    width: 1226px;
    ul li:nth-child(1) {
      text-align: center;
      height: 40px;
      font-size: 16px;
      font-weight: 700;
      line-height: 40px;
      color: #222;
      p {
        font-size: 12px;
        color: #222;
      }
    }
    ul li:nth-child(2) {
      padding-left: 10px;
    }
  }
  .Detail_title {
    font-size: 16px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: #e94c15;
    color: #fff;
    font-weight: 700;
    padding-left: 10px;
    span {
      float: right;
      margin-right: 15px;
      cursor: pointer;
    }
    i {
      float: right;
      font-size: 24px;
      line-height: 40px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}
</style>
